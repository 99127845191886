.column-filter__wrapper {
  .p-slidemenu {
    height: 150px;

    @media screen and (height >= 780px) {
      height: 250px;
    }

    @media screen and (height >= 900px) {
      height: 330px;
    }

    .p-slidemenu-wrapper {
      height: inherit !important;
    }
  }
}

.p-slidemenu.p-component {
  border-radius: 0 !important;
  border: 1px solid #ddd;
  width: initial;
  padding: 0 !important;

  &.p-menu {
    // One column is 435 + approx width of scrollbar

    // Chosen to fit in form-article-group.component.html

    // If used somewhere else this may not fit and has to go into component's style
    width: 450px;
    padding: 0;

    &.p-component {
      @include attribute(border-color, grey-light);
    }
  }

  .p-slidemenu-content {
    min-height: 100%;
  }

  .p-menu-list {
    padding-bottom: 30px !important;
  }

  .p-menuitem-link {
    padding: 0.4em 0.6em !important;
    white-space: normal !important;

    @include attribute(color, primary);

    border-width: 0 !important;
    border-left: 3px solid transparent !important;
  }

  .p-menuitem-link.p-state-hover {
    @include attribute(background-color, white-almost);

    border-left: 3px solid;

    @include attribute(border-left-color, secondary);
  }

  .p-menu-parent .p-menu-child {
    padding: 0 !important;
  }

  .p-menuitem-text {
    width: calc(100% - 20px);
  }

  .p-submenu-icon {
    margin-top: 2px;
  }

  .p-slidemenu-backward {
    width: auto !important;
    margin-bottom: 0 !important;
    bottom: -22px;
    font-family: "Noto Sans", sans-serif;
    text-align: center;
    position: absolute;
    padding: 0;

    @include attribute(color, primary);
  }
}
