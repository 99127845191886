table {
  @extend %text;

  @include mb(m);

  width: 100%;
  border: 0;
  border-spacing: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  @include attribute(background-color, secondary-light);
}

th,
td {
  border-bottom: 1px solid;

  @include attribute(border-bottom-color, grey-light);

  border-right: 1px solid;

  @include attribute(border-right-color, grey-light);

  &:last-child {
    border-right: none;
  }
}

th {
  @include p(0, m);

  position: relative;

  @include attribute(color, primary);

  text-align: left;
  height: 50px;
  @extend %text--strong;

  border-bottom: 2px solid;

  @include attribute(border-bottom-color, primary-light);

  white-space: nowrap;
}

tr {
  @include attribute(background-color, white);
}

tbody tr {
  &:hover {
    @include attribute(background-color, secondary-light);
  }

  &.archived {
    opacity: 0.5;
  }
}

td {
  @include p(0, m);

  border-radius: 0;
  vertical-align: middle;
  height: 50px;

  @include attribute(color, primary);

  &.action-column {
    text-align: right;
  }

  &.archived {
    opacity: 0.5;
  }
}

.list-entries-wrapper {
  @include clearfix;
  @include m(xs, 0, 0, 0);

  .label {
    @include mr(s);
    @include p(xs, 0);

    float: left;
    text-align: left;

    @include attribute(color, primary);
  }

  .list-entry {
    float: left;

    a {
      @include p(xs);

      text-decoration: none;
      display: block;
      cursor: pointer;

      @include attribute(color, primary);

      min-width: 28px;
      text-align: center;

      &:hover {
        @extend %text--strong;
      }
    }

    &.active a {
      @include attribute(color, secondary);
    }
  }
}

.empty-list {
  @extend %text;

  @include pl(m);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  @include attribute(color, primary-dark);

  span {
    @include pl(xs);
  }

  cursor: default;
}

.scroll-container {
  overflow-x: auto;
}
