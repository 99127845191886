.item {
  width: 100%;
  position: relative;
  height: 64px;

  @include attribute(background-color, white);

  overflow: hidden;
  display: flex;

  // used when dashboard-widgets has no items
  &.item--empty {
    height: auto;

    .item__description {
      overflow: visible;
      white-space: normal;
    }
  }

  &.item--link {
    cursor: pointer;

    @include sweep(secondary-light);
  }

  &:not(.item--last)::after {
    content: "";
    position: absolute;
    width: calc(100% + 3px);
    height: 1px;

    @include attribute(background-color, white-almost);

    left: -3px;
    z-index: 999;
    bottom: 0;
  }

  /** item elements */
  .item__image {
    @include p(m);

    border-left: 3px solid;

    @include attribute(border-left-color, primary-light);

    &.item__image--light {
      opacity: 0.5;
    }
  }

  .item__content {
    @include p(m, m, m, 0);

    flex: 1;
    overflow: hidden;
  }

  .item__date {
    @extend %text--strong;
  }

  .item__description {
    @extend %text;

    @include attribute(color, primary-light);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .item__name {
    @extend %text;

    @include attribute(color, primary);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @extend %text--strong;

    margin-bottom: 1px;
  }

  .item__link {
    @include m(m, s);
  }
}

.initials {
  @extend %text--small;

  display: inline-block;
  text-transform: uppercase;

  @include attribute(color, primary-light);
}
