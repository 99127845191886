// stylelint-disable scss/dollar-variable-pattern
@use "sass:map";

$sm: 576px;
$md: 640px;
$lg: 1025px;
$xl: 1440px;

$gutter: map.get($spacing-sizes, xs);
$fieldMargin: map.get($spacing-sizes, xs);
$fieldLabelMargin: map.get($spacing-sizes, xs);
$helperTextMargin: map.get($spacing-sizes, xs);

$spacer: map.get($spacing-sizes, s);

@import "primeflex/src/variables";

$breakpoints: (
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
);

@import "primeflex/src/mixins";

// grid system .grid, .col-1, etc
@import "primeflex/src/grid";

// display .hidden, .inline, etc
@import "primeflex/src/display";

// flex layout .flex, .justify-content-center
@import "primeflex/src/flexbox";
