h4.less-margin-bottom {
  @include mb(xs);
}

.dialog-infotext {
  @include m(0, 0, xxl);

  @extend %text--large;

  text-transform: none;

  @include attribute(color, primary-light);
}

.dialog-errortext {
  @include m(0, 0, xxl);
  @include p(s, xxl, s, m);

  @extend %text--large;

  text-transform: none;

  @include attribute(color, fail);

  border-left: 3px solid;

  @include attribute(border-left-color, fail);
}
