@use "sass:string";

@mixin create-context($classes...) {
  @each $class in $classes {
    #{$class} & {
      @content;
    }
  }
}

@function str-replace($string, $search, $replace: "") {
  $index: string.index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(
      str-slice($string, $index + str-length($search)),
      $search,
      $replace
    );
  }

  @return $string;
}

@mixin create-host-context($classes...) {
  $selector: &;

  @each $class in $classes {
    // transforms `:host-context(.class)` to `:host-context(.theme .class)`
    @if str-index(#{$selector}, ":host-context(") {
      @at-root #{str-replace(#{$selector}, ":host-context(", ":host-context(#{$class} ")} {
        @content;
      }
    }

    // transforms `.class :host-context`  to `.theme .class :host-context`
    @else if str-index(#{$selector}, ":host-context") {
      @at-root #{$class} & {
        @content;
      }

      @error "Do not use :host-context without (). Do use :host-context(.classname) instead";
    }

    // transforms `:host(.class)`  to `:host-context(.theme):host(.class) .class`
    @else if str-index(#{$selector}, ":host(") {
      @at-root #{str-replace(#{$selector}, ":host", ":host-context(#{$class}):host")} {
        @content;
      }

      @error "Do not use :host with (). Use :host.classname instead";
    }

    // transforms `:host .class`  to `:host:host-context(.theme) .class`
    @else if str-index(#{$selector}, ":host") {
      @at-root #{str-replace(#{$selector}, ":host", ":host:host-context(#{$class})")} {
        @content;
      }
    }

    // transformms `.class`  to `:host-context(.theme) .class`
    @else {
      #{':host-context(#{$class})'} & {
        @content;
      }
    }
  }
}
