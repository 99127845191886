.inline-block {
  display: inline-block;
}

.hidden {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}

.clearfix {
  @include clearfix;
}
