/*
    Class names with periods are illegal in stylelint, but required in main-list-view.
*/
/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-max-compound-selectors */
pro-pricealteration-step-list {
  .main-list .main-list__cell {
    &.cell-path--attribute\.price_base,
    &.cell-path--attribute\.discount,
    &.cell-path--attribute\.surcharge,
    &.cell-path--attribute\.price\.attribute\.discount,
    &.cell-path--attribute\.price_norm_1,
    &.cell-path--attribute\.price_norm_10,
    &.cell-path--attribute\.price_norm_100,
    &.cell-path--attribute\.price_norm_1000,
    &.cell-path--attribute\.clearing_deviation_price_assignment,
    &.cell-path--attribute\.clearing_deviation_price_savings,
    &.cell-path--attribute\.clearing_deviation_price_calculated_assignment,
    &.cell-path--attribute\.clearing_deviation_price_calculated {
      @extend %text--strong;

      width: 260px;
    }

    &.cell-path--attribute\.clearing_deviation_price_calculated_valid_from,
    &.cell-path--attribute\.clearing_deviation_price_calculated_valid_to {
      width: 260px;
    }
  }
}

pro-import-journals,
.own-catalog-export-journals__main-list {
  .main-list__cell {
    &.cell-path--status {
      width: 62px;
    }

    &.cell-path--filename {
      width: 330px;
    }

    &.cell-path--user_label {
      width: 250px;
    }
  }
}

.mapping-articles {
  .main-list__cell {
    &[class*="cell-path--attribute."] {
      @include attribute(background-color, secondary-light);
    }

    &.cell-path--attribute\.creditor_name {
      width: 220px;
    }

    &.cell-path--attribute\.status {
      width: 62px;

      @include attribute(background-color, white-almost);
    }
  }
}

.own-catalog-export-list {
  .main-list__cell {
    &:last-child {
      flex-grow: 0;
    }

    &.cell-path--attribute\.price_norm_1 {
      width: 240px;
      @extend %text--strong;

      .cell__container {
        justify-content: space-between;
      }

      .price-deviation-percent {
        font-weight: normal;
      }
    }

    &.cell-path--attribute\.clearing_type {
      width: 62px;
    }

    &.cell-path--attribute\.number {
      flex-grow: 0;
    }

    &.cell-path--attribute\.name {
      flex-grow: 1;
    }
  }
}

pro-article-assignment {
  .main-list .main-list__cell:first-child {
    width: 135px;
  }
}

pro-pricelist-condition-export {
  .main-list__cell {
    &.cell-path--attribute\.classification_valid_to {
      @include attribute(background-color, secondary-light);
    }
  }
}
