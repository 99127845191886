.p-autocomplete {
  .p-autocomplete-input {
    @include input;

    height: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.p-autocomplete-dd {
    width: 100%;

    .p-button-label {
      display: none;
    }
  }

  .p-autocomplete-dropdown {
    @include attribute(background-color, secondary-dark);
    @include attribute(color, white);

    @extend %text;

    width: $input-height;
    min-width: $input-height;
    border: none;

    &:hover {
      @include attribute(background-color, secondary-dark, lighten, $theme-hover-color-correction);
    }
  }

  .p-autocomplete-loader {
    right: 45px;
  }
}

.p-autocomplete-panel {
  @include attribute(background-color, white);
  @include box-shadow-dropdown;

  width: auto;
  max-width: 100%;

  @media screen and (height <= 900px) {
    max-height: 260px !important;
  }

  @media screen and (height <= 810px) {
    max-height: 220px !important;
  }

  @media screen and (height <= 765px) {
    max-height: 160px !important;
  }

  @media screen and (height <= 700px) {
    max-height: 130px !important;
  }
}

.p-autocomplete-item {
  @include attribute(color, primary);
  @include element-border;
  @include m(0);

  @extend %text;

  padding: $input-spacing;
  position: relative;

  &:hover,
  &.p-highlight {
    @include element-border-active(secondary);
    @include attribute(background-color, white-almost);
  }
}

.register {
  .p-autocomplete-input {
    @include p(s);

    background-color: $color-grey-light;
    @extend %text--strong;

    text-shadow: none;
  }
}

.form-autocomplete--light {
  .p-autocomplete-input {
    @include attribute(background, white);

    &:hover {
      @include attribute(background, grey-light);
    }
  }
}

.primeng-autocomplete {
  .p-autocomplete-token-label {
    max-width: 345px;
    text-overflow: ellipsis;

    /* Required for text-overflow */
    white-space: nowrap;
    overflow: hidden;
  }

  .p-disabled {
    pointer-events: initial;
  }
}

.autocomplete__wrapper {
  &--clearable {
    .p-autocomplete-input {
      @include pr(xl);
    }
  }
}
