/* You can add global styles to this file, and also import other style files */
@import "default";

@include icons-material-symbols("/assets/icons");

html {
  min-width: 1024px;
  height: 100%;
  transform: translate(0);
}
