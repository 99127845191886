/* stylelint-disable property-disallowed-list */

@font-face {
  font-family: Poppins;
  src: url("/assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("/assets/fonts/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("/assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/Poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("/assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("/assets/fonts/Poppins-Bold.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    local("NotoSans"),
    url("/assets/fonts/NotoSans-Regular.woff2") format("woff2"),
    url("/assets/fonts/NotoSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  src: local("Noto Sans"),
    local("NotoSans"),
    url("/assets/fonts/NotoSans-Bold.woff2") format("woff2"),
    url("/assets/fonts/NotoSans-Bold.woff") format("woff");
}
