.card.person-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .person-card__content {
    display: flex;
  }

  .person-card__image {
    .profile-image {
      width: 100px;
      height: 100px;

      .image {
        background: no-repeat center center;
        background-size: cover;
        width: 100px;
        height: 100px;
        border-radius: 50%;

        @include attribute(background-color, grey-light);
      }
    }
  }

  .initials-container {
    width: 100px;
    height: 100px;
    border: 1px solid;

    @include attribute(border-color, primary-light);

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .initials {
      @include mt(0);

      @include attribute(color, primary-light);
    }
  }

  .person-card__detail {
    @include p(xs, l);
    @include attribute(color, primary-dark);

    flex: 1;
    justify-content: center;

    .detail__name {
      @extend %heading;

      display: inline-block;
      width: 100%;
    }

    .detail__position,
    .detail__organisation {
      @extend %text;

      @include attribute(color, primary-light);
    }

    .detail__contacts {
      @extend %text;

      @include mt(m);

      .contact-value {
        @include ml(xs);

        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
