%text-default {
  font-family: "Noto Sans", sans-serif;
  letter-spacing: 0.2px;
  font-weight: initial;
}

%text {
  @extend %text-default;

  font-size: 12px;

  @media print {
    font-size: 16px;
  }
}

%text--large {
  @extend %text-default;

  font-size: 14px;

  @media print {
    font-size: 18px;
  }
}

%text--medium {
  @extend %text;
}

%text--small {
  @extend %text-default;

  font-size: 11px;

  @media print {
    font-size: 15px;
  }
}

%text--strong {
  @extend %text-default;

  font-weight: 700;
}

%heading-default {
  font-family: Poppins, sans-serif;
  font-weight: initial;
}

%heading {
  @extend %heading-default;

  font-size: 18px;
  letter-spacing: 0.2px;

  * {
    @extend %heading-default;
  }

  @media print {
    font-size: 22px;
  }
}

%heading--large {
  @extend %heading-default;

  font-size: 24px;
  letter-spacing: 0;

  @media print {
    font-size: 28px;
  }
}

%heading--medium {
  @extend %heading;
}

%heading--small {
  @extend %heading-default;

  font-size: 14px;
  letter-spacing: 0.3px;

  @media print {
    font-size: 18px;
  }
}

%heading--semi-strong {
  @extend %heading-default;

  font-weight: 600;
}

%heading--strong {
  @extend %heading-default;

  font-weight: 700;
}

%heading--xlarge {
  @extend %heading;

  font-size: 56px;
}

%line-height--text {
  line-height: 18px;
}

// custom special cases - do not use it - try an other way

%text-charts--large {
  @extend %text-default;

  font-size: 2.2em;
}

%text-charts--extralarge {
  @extend %text-default;

  font-size: 40px;
}

%text-charts--medium {
  @extend %text-default;

  font-size: 1.3em;
}

%text-charts--semi-medium {
  @extend %text-default;

  font-size: 1.2em;
}

%heading-chart--large {
  @extend %heading;

  font-size: 40px;
}
