.p-multiselect {
  width: 100%;
}

.p-multiselect-panel {
  @include attribute(background-color, white);
  @include box-shadow-dropdown;

  width: auto;
  max-width: 100%;

  .p-multiselect-item {
    @include element-border;

    @extend %text;

    @include m(0);

    padding: $input-spacing;
    flex-direction: column;
    justify-items: right;
    align-items: initial !important;

    &:hover {
      @include element-border-active(secondary);
      @include attribute(background-color, white-almost);
    }

    &:focus,
    &:active {
      @include attribute(background-color, white-almost, darken, $theme-hover-color-correction);

      outline: none;
    }
  }

  .p-checkbox,
  .p-multiselect-close {
    display: none !important;
  }

  .p-highlight {
    @include attribute(color, secondary-dark);
  }

  .p-multiselect-header {
    @include m(0);

    padding: $input-spacing;
  }

  .p-multiselect-empty-message {
    padding: 10px 20px;
  }
}

.p-multiselect-label-container {
  @include input;
}

.p-multiselect-trigger {
  @include attribute(background-color, secondary-dark);
  @include attribute(color, white);

  width: $input-height;
  min-width: $input-height;

  &:hover {
    @include attribute(background-color, secondary-dark, lighten, $theme-hover-color-correction);
  }
}

[disabled] {
  .p-disabled {
    pointer-events: initial;
  }

  .p-multiselect-label-container {
    @include attribute(color, grey-dark);

    pointer-events: initial;

    &:hover {
      @include attribute(background-color, grey-light, lighten, 3);
    }
  }

  .p-multiselect-trigger {
    pointer-events: none;
  }
}
