.widget {
  $root: &;

  @include pb(xxl);

  height: 100%;
  position: relative;

  #{&}__button {
    @include p(m, m, m, 0);

    position: relative;
    cursor: pointer;
    border-top: 1px solid;

    @include attribute(border-top-color, grey-light);

    &::after {
      content: " ";
      background: transparent url("/assets/img/icons/dark/right-dark.svg") no-repeat center center;
      background-size: 16px;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -8px;
    }
  }

  &#{&}--fluid {
    @include pt(0);

    .widget__header {
      position: static;
      height: auto;
    }
  }

  &#{&}--has-info {
    #{$root}__content {
      height: 576px;
    }
  }

  #{&}__wrapper {
    width: 100%;

    &#{$root}__wrapper--highlight {
      @include attribute(background-color, secondary-light);
    }
  }

  #{&}__header {
    @extend %text--large;

    @include p(l, l, s);

    overflow: hidden;
    height: 63px;
    margin-bottom: 1px;
    border-left: 3px solid;

    @include attribute(border-left-color, primary);
  }

  #{&}__info {
    @extend %text;

    @include p(0, l, l);

    margin-top: -15px;
    height: 78px;
    overflow: hidden;
    border-left: 3px solid;

    @include attribute(border-left-color, primary);
    @include attribute(color, primary-light);
  }

  #{&}__sleeve {
    @include sleeve-layout;
    @include sleeve-color(white, primary);

    padding: ($spacing-xs + 3px) $spacing-s $spacing-xs $spacing-s;
    min-width: 65px;
  }

  #{&}__content {
    overflow-y: inherit;

    &--scrolling {
      overflow-y: auto;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 30px;
        position: fixed;
        bottom: 20px;
        left: 0;
        z-index: 1;
        pointer-events: none;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 100%) 0%, rgba(255, 255, 255, 0%) 100%);

        @include theme(dark) {
          background: linear-gradient(0deg, rgba(55, 59, 69, 100%) 0%, rgba(55, 59, 69, 0%) 100%);
        }
      }
    }
  }

  #{&}__list {
    @extend %text;

    #{$root}__list-item {
      @include p(s, m, s, 0);

      position: relative;
      width: 100%;
      border-bottom: 1px solid;

      @include attribute(border-bottom-color, grey-light);

      &:last-child {
        border-bottom: 0;
      }

      &::after {
        content: " ";
        background: transparent url("/assets/img/icons/dark/right-dark.svg") no-repeat center center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -8px;
      }
    }
  }
}

.widget.widget--card {
  @include pb(0);

  height: 280px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 10%);

  .widget__wrapper {
    @include p(l);

    height: 280px;

    @include attribute(background-color, white);

    &--highlight {
      @include attribute(background-color, secondary-light);
    }
  }

  .widget__header {
    height: auto;
    @extend %text--strong;
  }

  .widget__info {
    @include m(xs, 0, m);

    height: auto;

    @include attribute(color, primary-light);
  }

  .widget__header,
  .widget__info {
    @include p(0);

    border: 0;
  }

  .widget__content {
    @include clearfix;
  }
}

.widget-config-wrapper {
  @include mt(s);

  flex: 1;
  height: 400px;
  overflow-y: auto;

  .widget-config-info {
    @include mt(s);

    opacity: 0.7;
    @extend %text;
  }
}

.widget-config {
  @include p(m, s);

  cursor: pointer;
  position: relative;
  border-bottom: 2px solid;

  @include attribute(border-bottom-color, primary-light);
  @include attribute(color, white-static);

  &:hover {
    @include attribute(background-color, primary-light-static);
  }

  .headline {
    @include pr(xl);
    @include mb(xs);

    @extend %text;
  }

  .subtitle {
    opacity: 0.5;
    @extend %text--small;
  }

  .material-symbols {
    position: absolute;
    top: 13px;
    right: 10px;
  }
}

.widget-options {
  @include mt(l);

  .pro-button {
    width: 100%;
  }
}

$widget-control-animation-time: 0.3s;
$widget-control-animation-delay: 0s;
$widget-control-animation-transition: transform $widget-control-animation-time ease-in-out;

.widget-wrapper {
  position: relative;
  user-select: none;

  &[dragged-over]::after {
    content: "";
    position: absolute;
    top: 0.5em;
    right: -5px;
    width: 10px;
    height: calc(100% - 1em);
    z-index: 0;

    @include attribute(background-color, signal);
  }

  &:hover {
    .widget-controls {
      @include animate-fade-in(
        $widget-control-animation-time,
        $widget-control-animation-delay,
        $widget-control-animation-transition
      );

      transform: translate(0);
    }
  }
}

.widget-controls {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;

  @include animate-fade-out(
    $widget-control-animation-time,
    $widget-control-animation-delay,
    $widget-control-animation-transition
  );

  transform: translate(50%, 0);

  .widget-control {
    @include mb(xs);
    @include p(xs);

    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 20%);
    border-radius: 50%;
    cursor: pointer;
    user-select: none;

    @include attribute(background-color, white);

    &:hover {
      @include attribute(background-color, white, darken);
    }

    &.widget-control--primary {
      @include attribute(background-color, secondary);

      &:hover {
        @include attribute(background-color, secondary, darken);
      }
    }
  }
}
