.main-list__x-scroller {
  position: relative;
  height: 100%;
  padding: 3px $spacing-xxl 3px $spacing-xxl; // 3px padding top and bottom to make box-shadow visible
  overflow-x: auto;
  overflow-y: hidden;
}

.main-list__y-scroller {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
  padding-left: 10px;
}

.main-list {
  @extend %text;

  @include mb(l);
  @include attribute(background-color, white);
  @include attribute(color, black-almost);

  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 12%);
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;

  .main-list__rows {
    direction: ltr;
  }

  .main-list__row {
    display: flex;
    height: 35px;
    overflow: hidden;
    border-bottom: 1px solid;
    position: relative;

    @include attribute(border-bottom-color, grey-light);
    @include attribute(background, white);

    &:hover {
      cursor: pointer;

      @include attribute(background-color, secondary-light);
    }

    &.main-list__row--empty {
      height: auto;
      border-bottom: 0;

      &:hover {
        cursor: default;

        @include attribute(background-color, white);
      }
    }
  }

  .main-list__row--header {
    display: inline-flex;
    height: 44px;
    white-space: nowrap;

    @include attribute(background-color, white);
  }


  .cell--header--highlighted {
    border: 2px solid $color-secondary;
  }

  .main-list__rows-inner {
    @include p(xs, 0);
    @include attribute(background, secondary-light);

    position: relative;
  }

  .main-list__row-inner {
    display: flex;
    height: 26px;

    &:hover {
      @include attribute(background-color, white);
    }

    .main-list__cell i {
      cursor: default !important;
    }
  }

  .main-list__cell {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 150px;
    height: 100%;
    border-right: 1px solid;

    @include attribute(border-right-color, grey-light);

    &:first-child,
    &.main-list__cell--add,
    &.main-list__cell--editing {
      width: 125px;
      cursor: default;

      .cell__container {
        @include p(0, xs);
      }
    }

    &:last-child {
      flex-grow: 1;
    }

    &.main-list__cell--editing {
      width: 260px;
    }

    img {
      @include m(0, auto);

      max-width: 50px;
      max-height: 30px;
      display: block;
    }

    .td-logo {
      @include m(0, auto);
    }
  }

  .main-list__cell--highlighted {
    border-left: 2px solid $color-secondary;
    border-right: 2px solid $color-secondary;
  }

  .main-list__cell--highlighted--last {
    border-bottom: 2px solid $color-secondary;
  }

  .cell__container {
    @include p(0, m);

    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    &.cell__container--centered {
      text-align: center;
    }

    &.asc,
    &.desc {
      .cell__content {
        @include pl(0);
        @include attribute(color, secondary);
      }
    }

    main-list-value {
      width: 100%;
    }

    .cell__content {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .main-list__cell--header {
    @include attribute(background-color, white);

    @extend %text--strong;

    border-bottom: 2px solid;

    @include attribute(border-bottom-color, primary-light);

    .asc,
    .desc {
      @include pl(xl);
    }

    .asc>.ico--up,
    .asc>.ico--sort-up,
    .desc>.ico--down,
    .desc>.ico--sort-down {
      display: block;
    }

    .cell__container {
      cursor: default;
    }

    .cell__content {
      text-align: left;
      cursor: default;

      &.sortable {
        cursor: pointer;
      }
    }

    .column-header__width-handle {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 6px;
      z-index: 1;
      cursor: ew-resize;
    }

    .ico--up,
    .ico--sort-up,
    .ico--down,
    .ico--sort-down {
      position: absolute;
      left: 11px;
      display: none;

      @include attribute(color, secondary);
    }

    &--highlighted {
      border: 2px solid $color-secondary;
    }

  }

  // make first column more narrow if there is no template to be shown
  &.main-list--without-first-cell .main-list__cell {
    &:first-child {
      width: 60px;

      &.main-list__cell--editing {
        width: 260px;
      }
    }
  }

  &.main-list--empty-first-column
  {
    .main-list__cell {
      &:first-child {
        display: none;
      }
    }

    .cell__container {
      border-left: 1px solid;

      @include attribute(border-left-color, grey-light);
    }

    .no-left-padding {
      padding-left: 0;
    }
  }


}

/**
 * Different List Types
 */
.list {
  width: 100%;
  height: 100%;

  &--sticky {
    .list__wrapper {
      @include p(xxl, xxl, 0);

      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .list__content {
      margin: 0 (-$spacing-xxl);
      overflow: hidden;
      height: 100%;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;

    h4 {
      @include mt(0);

      .number-value {
        position: relative;
        top: -2px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__changed {
    @extend %text;

    @include mb(xxl);
    @include attribute(color, primary);

    background: transparent;
    margin-top: -30px;
  }
}

/*
CURRENTLY ONLY NEEDED FOR STATIC LIST
TODO: Replace static-list and remove
*/
.list-view {
  .column--sortable .cell--header span {
    cursor: pointer;
  }

  .cell--header--highlighted {
    border: 2px solid $color-secondary;
  }

  .cell--header {
    display: inline-block;
    @extend %text--strong;

    z-index: 1;
    border-bottom: 2px solid;

    @include attribute(border-bottom-color, primary-light);

    >pro-form-autocomplete {
      @include mr(xs);
    }

    span {
      cursor: default;
      white-space: nowrap;
    }

    >.ico--sort-up,
    >.ico--sort-down {
      position: absolute;
      left: 11px;
      top: 16px;
      display: none;

      @include attribute(color, secondary);
    }

    &.asc>.ico--sort-up,
    &.desc>.ico--sort-down {
      display: block;
    }

    &.asc,
    &.desc {
      @include pl(xl);
    }

    .cell__content {
      &-subtitle {
        @extend %text--small;

        display: block;
        margin-top: -3px;
      }
    }
  }

  .cell--highlighted {
    border-left: 2px solid $color-secondary;
    border-right: 2px solid $color-secondary;
  }

  .cell--highlighted--last {
    border-bottom: 2px solid $color-secondary;
  }

}

// Change some styles if used in table
table.list-view {
  .cell--header {
    border: none;
  }
}

@media only screen and (width <=1124px) {
  .list {
    &--sticky {
      .list__content {
        height: calc(100% - 54px);
      }
    }
  }
}