/**
 * Team23 Color Theme
 *
 * DO NOT CREATE NEW COLORS THAT ARE NOT DEFINED IN THE DESIGN SYSTEM!
 * https://app.zeplin.io/project/5addee3fc47dfd347758d48c/screen/5ae9c42b9b2863eb663cac57
 */

// TODO: remove these colors when theming refactoring is done

/////////////////////////

////// Hauptfarben //////

/////////////////////////

$color-primary: #2d5377;
$color-primary-light: #99aaba;
$color-primary-dark: #2e364e;

$color-secondary: #37adb8;
$color-secondary-dark: #37adb8;
$color-secondary-light: #ebf8fa;

$color-tertiary: #7b204f;
$color-tertiary-dark: #61193e;

$color-quarternary: #ef4070;

//////////////////////////

////// Systemfarben //////

//////////////////////////

$color-fail: #d95d6c;
$color-fail-light: #fbeef0;

$color-success: #72c466;
$color-success-light: #f0f9ef;

$color-warning: #d38124;
$color-warning-light: #fceee5;

$color-signal: #fff200;

$color-black: #000;

//////////////////////

////// Grautöne //////

//////////////////////

$color-white: #fff;
$color-white-almost: #f2f2f2; // AKA Almost-White

$color-grey-light: #eaeaea;
$color-grey: #d4d4d4;
$color-grey-dark: #858585;

$color-black-almost: #3e3e3e; // AKA Almost-Black

//////////////////////////

////// Chartcolors ///////

//////////////////////////

$color-chart-turquoise: #37adb8;
$color-chart-blue: #3e89d0;
$color-chart-purple: #8e72d1;
$color-chart-grey: #6c6c6c;
$color-chart-green: #72c466;
$color-chart-yellow: #e2c045;
$color-chart-orange: #db9342;
$color-chart-red: #d95d6c;
