// stylelint-disable declaration-no-important

// Print View

@mixin print {
  .page__header {
    display: none;
  }

  .page__wrapper {
    @include pt(0);
  }

  .page__sidebar-left {
    display: none;
  }

  navbar-options {
    display: none;
  }

  pro-detail-tabs {
    display: block;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .detail-tabs {
      .tab__container,
      .detail__bar,
      .detail__flyout {
        display: none;
      }

      .detail__navigation {
        width: auto !important;
        position: relative !important;
        margin: 40px 40px 0;
        box-shadow: none;
      }

      .detail__content {
        padding-left: 0 !important;
        height: auto !important;
      }
    }
  }

  .tab-header {
    label,
    t23-icon {
      display: none;
    }
  }

  pro-tab:not(:first-child) .tab-header {
    display: none;
  }

  pro-list-property-tab:not(:first-child) pro-tab .tab-header {
    display: none;
  }

  pro-contract-info-tab:not(:first-child) pro-tab .tab-header {
    display: none;
  }

  // context menu
  .work-in-progress {
    display: none !important;
  }

  .detail__flyout {
    display: none;
  }

  .card {
    &:not(.print-overlay) {
      box-shadow: none;
    }

    &.card--closed {
      display: none;
    }

    .toggle-card {
      display: none;
    }
  }
}

html body.print {
  @include print;

  @media screen {
    padding: 25px 449px 25px 25px;

    main {
      @include attribute(background-color, white);
    }
  }
}

@media print {
  // http://stackoverflow.com/questions/1960939/disabling-browser-print-options-headers-footers-margins-from-page
  @page {
    size: a4 portrait;
    margin: 40px; /* this affects the margin in the printer settings */
  }

  body {
    @include print;

    * {
      color: #000;
    }
  }
}
