/* stylelint-disable selector-no-vendor-prefix */

::-webkit-input-placeholder {
  @include attribute(color, grey-dark);

  text-shadow: none;
}

::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  @include attribute(color, grey-dark);

  text-shadow: none;
}
