/* STYLING BY PATH */
/* stylelint-disable selector-class-pattern */
.list-view .cell-path--,
.main-list .main-list__cell.cell-path--,
.cell-path-- {
  &attribute\.packaging_unit,
  &relation\.article\.attribute\.packaging_unit,
  &attribute\.base_unit,
  &relation\.article\.attribute\.base_unit,
  &property\.eclass,
  &relation\.article\.property\.eclass, {
    text-align: center;
  }

  &attribute\.price\.attribute\.price_date,
  &relation\.article\.attribute\.price\.attribute\.price_date {
    text-align: center;
  }

  &attribute\.volume_quantityofeach {
    width: 100px;
  }

  &attribute\.price_base,
  &relation\.article\.attribute\.price\.attribute\.price_base,
  &attribute\.price\.attribute\.price_base {
    text-align: right;
    width: 100px;
  }

  &attribute\.discount,
  &relation\.article\.attribute\.price\.attribute\.discount,
  &attribute\.price\.attribute\.discount {
    text-align: right;
    width: 100px;
  }

  &attribute\.price_end,
  &relation\.article\.attribute\.price\.attribute\.price_end,
  &attribute\.price\.attribute\.price_end {
    text-align: right;
    @extend %text--strong;

    width: 100px;
  }

  &attribute\.price_norm_1,
  &relation\.article\.attribute\.price\.attribute\.price_norm_1,
  &attribute\.price\.attribute\.price_norm_1 {
    text-align: right;
    width: 100px;
  }

  &attribute\.price_norm_10,
  &relation\.article\.attribute\.price\.attribute\.price_norm_10,
  &attribute\.price\.attribute\.price_norm_10 {
    text-align: right;
  }

  &attribute\.price_norm_100,
  &relation\.article\.attribute\.price\.attribute\.price_norm_100,
  &attribute\.price\.attribute\.price_norm_100 {
    text-align: right;
  }

  &attribute\.price_norm_1000,
  &relation\.article\.attribute\.price\.attribute\.price_norm_1000,
  &attribute\.price\.attribute\.price_norm_1000 {
    text-align: right;
  }

  &attribute\.volume_quantityofeach,
  &relation\.article\.attribute\.volume_quantityofeach, {
    text-align: right;
  }

  &attribute\.packaging_quantityofeach,
  &relation\.article\.attribute\.packaging_quantityofeach, {
    text-align: right;
  }

  &attribute\.ingredient_intensity,
  &property\.ingredient_intensity,
  &relation\.article\.property\.ingredient_intensity, {
    text-align: right;
  }

  &attribute\.quantity {
    text-align: right;
  }

  &attribute\.clearing_deviation_price_assignment {
    text-align: right;
  }

  &attribute\.clearing_deviation_price_savings {
    text-align: right;
  }
  
  &attribute\.clearing_deviation_price_calculated_assignment {
    text-align: right;
  }

  &attribute\.clearing_deviation_price_calculated{
    width: 250px;
  }

  &property\.eclass {
    width: 125px;
  }

  &attribute\.name {
    width: 350px;
  }

  &organization_pk {
    width: 250px;
  }

  &attribute\.firm,
  &relation\.organization {
    width: 400px;
  }

  &property\.supplier\.category {
    width: 210px;
  }

  &property\.recommendation {
    width: 62px;
  }

  &sender,
  &receipt {
    width: 250px;
  }

  &subject {
    width: 400px;
  }
}
