@mixin shadow($opacity: 0.2) {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, $opacity);
}

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4), $inset: "") {
  @if $inset != "" {
    box-shadow: $inset $x $y $blur $color;
  } @else {
    box-shadow: $x $y $blur $color;
  }
}

@mixin box-shadow-dropdown() {
  @include box-shadow(0, 1px, 3px, 0 rgba(0,0,0,0.3));
}
