/**
 * TEAM23 Typography
 *
 * https://app.zeplin.io/project/5addee3fc47dfd347758d48c/screen/5ae9c42a69b558601a6156db
 */

.text {
  @extend %text;

  &--large {
    @extend %text--large;
  }

  &--medium {
    @extend %text--medium;
  }

  &--small {
    @extend %text--small;
  }

  &--strong {
    @extend %text--strong;
  }
}

.heading {
  @extend %heading;

  &--large {
    @extend %heading--large;
  }

  &--medium {
    @extend %heading--medium;
  }

  &--small {
    @extend %heading--small;
  }

  &--invert {
    @include attribute(color, white-static);
  }

  &--strong {
    @extend %heading--strong;
  }
}

%text-color {
  @include attribute(color, primary);
}

/* stylelint-disable no-duplicate-selectors */
.text {
  $root: &;

  @extend %text-color;

  &--invert {
    @include attribute(color, white-static);
  }

  &--link {
    @include attribute(color, secondary);

    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &--inactive {
    @include attribute(color, grey);

    cursor: default;
    pointer-events: none;

    &.text--invert {
      @include attribute(color, grey-dark);
    }
  }

  &--temperate {
    opacity: 0.7;
  }

  &--desat {
    @include attribute(color, black-almost);
  }

  &--warning {
    @include attribute(color, fail);
  }
}
