* {
  box-sizing: border-box;
}

html {
  min-width: 1024px;
  height: 100%;
  transform: translate(0);
  overflow: hidden;
}

html,
body {
  @extend %text;

  @include root-theme(color, primary);
  @include root-theme(background-color, white-almost);
  @include m(0);

  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

main {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

input,
textarea {
  @extend %text;
}

p {
  @include my(s);
}

button {
  @extend %text;
}

@media print {
  main {
    height: auto;
  }

  main.behind-dialog {
    display: none;
  }
}

pro-root > *:not(router-outlet) {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

/* stylelint-disable selector-type-no-unknown */
pro-default > .page > .page__wrapper > .relative > *:not(router-outlet),
crm-container,
catalog-container,
users-container,
me,
dashboard,
service-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
/* stylelint-enable */

t23-icon {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
