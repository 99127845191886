.navbar-menu-container {
  ul {
    @include m(0);
    @include p(0);

    display: block;
    width: 100%;
    box-shadow: 0 2px 6px 0 $color-black;
    position: relative;

    @include attribute(background-color, primary-dark-static);
  }

  li {
    list-style: none;
    border-top: 1px solid;

    @include attribute(border-top-color, primary-light-opaque);

    &.element__active {
      a {
        @include attribute(color, secondary);
      }
    }

    &:first-child {
      border-top: 0;
    }

    &:hover {
      @include attribute(background-color, primary-dark-static, darken, 5);
    }
  }

  a {
    @include p(m, l);

    @extend %text;

    cursor: pointer;
    display: flex;

    @include attribute(color, white-static);

    text-decoration: none;

    .navbar__element-text,
    .navbar__element-tempered-text {
      @include pl(s);

      position: relative;
      top: 1px;
    }

    .navbar__element-text {
      flex: 1;
    }
  }
}
