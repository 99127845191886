.card {
  $root: &;

  @include shadow(0.1);
  @include p(xl);

  display: block;

  @include attribute(background-color, white);

  position: relative;

  &#{&}--borderless {
    @include p(0);
  }

  &#{&}--nested-list {
    overflow: hidden;
  }

  &#{&}--toggle {
    #{$root}__content {
      display: none;

      &#{$root}__content--open {
        display: block;
      }
    }

    &#{$root}--with-headline {
      #{$root}__content {
        @include pt(l);
      }
    }

    @media print {
      .card__toggle {
        display: none;
      }

      &#{$root}__content {
        display: block !important;
      }
    }
  }

  &#{&}--primary {
    @include attribute(background-color, secondary-light);

    #{$root}__headline {
      @include attribute(color, primary);
    }
  }

  #{&}__wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    h1,
    h2,
    h3,
    h4,
    h5 {
      @include mt(0);
    }
  }

  #{&}__toggle {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    user-select: none;
  }

  /** Row for buttons in the lower right corner */
  #{&}__button-row {
    text-align: right;
  }

  #{&}__headline {
    @include pr(l);
    @extend %heading--semi-strong;
    @extend %heading--small;

    @include attribute(color, primary-light);

    cursor: pointer;
  }

  #{&}__subheadline {
    @extend %text;
  }

  /** Styles */

  &#{&}--inverted {
    @include attribute(background-color, primary-dark);

    > * {
      @include attribute(color, white);
    }
  }

  h5 {
    @include m(xs, 0, m);
  }
}
