/*
Generates following code

.m-0
.m-s
.ml-xs
.mr-s
.mt-m
.mb-l
.px-xl
.py-xxl

 */

// Margins

@each $direction-name, $direction-rule in $spacing-directions {
  @each $size-name, $size-value in $spacing-sizes {
    .m#{$direction-name}-#{$size-name} {
      @include margin($direction-name, $size-name);
    }
  }
}

@each $size-name, $size-value in $spacing-sizes {
  .mx-#{$size-name} {
    @include mx($size-name);
  }
}

@each $size-name, $size-value in $spacing-sizes {
  .my-#{$size-name} {
    @include my($size-name);
  }
}

@each $size-name, $size-value in $spacing-sizes {
  .m-#{$size-name} {
    @include m($size-name);
  }
}

// Padding

@each $direction-name, $direction-rule in $spacing-directions {
  @each $size-name, $size-value in $spacing-sizes {
    @if $size-name != auto {
      .p#{$direction-name}-#{$size-name} {
        @include padding($direction-name, $size-name);
      }
    }
  }
}

@each $size-name, $size-value in $spacing-sizes {
  @if $size-name != auto {
    .px-#{$size-name} {
      @include px($size-name);
    }
  }
}

@each $size-name, $size-value in $spacing-sizes {
  @if $size-name != auto {
    .py-#{$size-name} {
      @include py($size-name);
    }
  }
}

@each $size-name, $size-value in $spacing-sizes {
  @if $size-name != auto {
    .p-#{$size-name} {
      @include p($size-name);
    }
  }
}

.centered {
  @include m(0, auto);
}
