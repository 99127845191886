/* stylelint-disable selector-type-no-unknown */

/* Validation */
.p-inputtext.ng-invalid.ng-touched.ng-dirty,
p-dropdown.ng-invalid.ng-touched.ng-dirty > .p-dropdown,
p-dropdown.ng-invalid.ng-touched.ng-dirty > .p-dropdown .p-dropdown-label,
p-autocomplete.ng-invalid.ng-touched.ng-dirty > .p-autocomplete > .p-inputtext,
p-calendar.ng-invalid.ng-touched.ng-dirty > .p-inputtext,
p-inputmask.ng-invalid.ng-touched.ng-dirty > .p-inputtext,
p-listbox.ng-invalid.ng-touched.ng-dirty .p-inputtext,
p-multiselect.ng-invalid.ng-touched.ng-dirty > .p-multiselect,
p-selectbutton.ng-invalid.ng-touched.ng-dirty .p-button,
p-togglebutton.ng-invalid.ng-touched.ng-dirty .p-button {
  @include attribute(color, fail);
}
