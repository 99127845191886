// Icons

$icons-special: trend-negative,
  trend-positive,
  trend-neutral,
  big-notfound,
  big-success,
  big-warning,
  big-wait,
  big-error,
  checkmark-big;
$icons-white: delete, button-loader;

$sizes: 12, 16, 18, 20, 24, 28, 32, 40, 60, 80, 100, 140;

$rotations: 90, 180;

div,
span,
i {
  &.icon {
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;

    @each $size in $sizes {
      &.icon--#{$size} {
        width: #{$size}px;
        height: #{$size}px;
        min-width: #{$size}px;
        min-height: #{$size}px;
      }
    }

    @each $rotation in $rotations {
      &.icon--rotate-#{$rotation} {
        transform: rotate(#{$rotation}deg);
      }
    }

    @each $i_w in $icons-white {
      &.icon--#{$i_w} {
        background: transparent url("/assets/img/icons/white/#{$i_w}-white.svg") no-repeat center center;
        background-size: 100%;
      }
    }

    @each $i_s in $icons-special {
      &.icon--#{$i_s} {
        &.icon--special {
          background: transparent url("/assets/img/icons/special/#{$i_s}-special.svg") no-repeat center center;
          background-size: 100%;
        }
      }
    }

    &.icon--temperate {
      opacity: 0.5;
    }

    > img {
      width: 100%;
    }
  }
}
