@mixin element-border() {
  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 3px;
    transition: 0.3s ease-in-out;
    transform: translate(-3px);
    left: 0;
    top: 0;
    z-index: 9;
  }
}

@mixin element-border-active($colorKey) {
  &::before {
    transform: translate(0);

    @include attribute(background-color, $colorKey);
  }
}
