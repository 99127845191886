.contextmenu {
  max-width: 280px;

  &__item {
    @include p(s);

    display: flex;

    @include attribute(background-color, white);

    cursor: pointer;

    &:hover {
      @include attribute(background-color, white-almost);
    }

    t23-icon {
      @include pr(s);
    }

    &--success {
      @include attribute(color, success);
    }

    &--fail {
      @include attribute(color, fail);
    }

    &--disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}
