@mixin icons-material-symbols($icons-directory, $version: random(9999)) {
  %font {
    font-family: "Material Symbols", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }

  @font-face {
    font-family: "Material Symbols";
    font-style: normal;
    font-weight: 100 700;
    /* stylelint-disable function-url-quotes */
    src: url($icons-directory + '/material-symbols/material-symbols.woff2?' + $version) format("woff2");
  }

  .material-symbols {
    @extend %font;

    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;

    &--filled {
      @extend %font;

      font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 24;
    }

    @include dynamic-font-sizes(12, 48);

    &.padding-left { padding-left: 5px; }
    &.padding-right { padding-right: 5px; }

    &.rounded {
      border: 1px solid;
      border-radius: 50%;
    }
  }
}

// mixin which creates classes like below starting from 12 ending on 48
// &.md-48 { font-size: 48px; }
@mixin dynamic-font-sizes($start, $end) {
  @for $i from $start through $end {
    &.md-#{$i} {
      font-size: #{$i}px;
    }
  }
}
