.searchbar {
  @include mb(l);

  position: relative;

  input {
    @include m(0);
    @include p(s);
    @include pr(xxl);

    outline: none;
    border: none;
    border-bottom: 1px solid;
    width: 100%;

    @include attribute(border-bottom-color, grey);
    @include attribute(background-color, white);
  }

  &__icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
