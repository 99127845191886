@mixin sweep($colorKey) {
  position: relative;
  overflow: hidden;

  > * {
    position: relative;
    z-index: 99;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    opacity: 1;
    z-index: 88;

    @include attribute(background, $colorKey);
  }

  &:hover {
    &::before {
      transform: translateX(0);
    }
  }
}
