@use "sass:color";
@import "../abstracts/colors";

.hint {
  @include p(0, m, 0, m);

  align-items: center;
  border-radius: 5px;
  display: flex;

  &--warning {
    border: 1px solid color.adjust($color-warning-light, $lightness: -15%);
    background: $color-warning-light;
    color: $color-warning;
  }

  &--error {
    background: $color-fail;
  }
}
