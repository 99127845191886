detail-header {
  .header {
    @include p(xl, l, m);
    @include attribute(color, primary);

    text-align: center;
    position: relative;

    img {
      @include m(auto);

      max-width: 210px;
      max-height: 140px;
      display: block;
    }

    h3 {
      @include m(xl, 0);
    }
  }
}

.detail-bar-button {
  @include m(0, xs);

  display: inline;
}
