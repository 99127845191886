p-progressbar[ng-reflect-show-value="false"] .p-progressbar {
  height: 5px;
  box-shadow: none;
}

.p-progressbar {
  height: 1.2em;
  text-align: left;
  position: relative;
  overflow: hidden;

  @include attribute(background-color, grey-light);

  .p-progressbar-value {
    height: 100%;

    @include attribute(background-color, secondary);

    box-shadow: none;
  }

  .p-progressbar-label {
    @extend %text--small;

    @include attribute(color, primary-dark);
  }

  .p-progressbar-value-animate {
    transition: width 1s ease-in-out;
  }
}
