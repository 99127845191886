@mixin sleeve-layout($sleeve-size: 5px) {
  position: absolute;
  right: -$sleeve-size;
  top: 2 * $sleeve-size;
  min-width: 30px;
  padding: $sleeve-size;
  @extend %heading--semi-strong;

  border-radius: 1px 0 1px 1px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 10%);

  &::before {
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: $sleeve-size $sleeve-size 0 $sleeve-size;
    border-color: transparent;
    right: -$sleeve-size;
    top: -$sleeve-size;
  }
}

@mixin sleeve-color($color: white, $background-color: primary) {
  @include attribute(background-color, $background-color);
  @include attribute(color, $color);

  &::before {
    @include attribute(border-left-color, $background-color, darken, 20);
  }
}
