textarea,
input,
button {
  outline: none;

  @include attribute(color, black);

  background-color: transparent;
}

.button-row {
  @include mt(l);
}
