.overlay {
  position: fixed;
  z-index: 1000;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.overlay__fallback {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: block;

  @include attribute(background-color, backdrop);
}

.overlay-close {
  @include p(m);

  text-align: center;

  @include attribute(color, white);
  @include attribute(background-color, secondary);

  cursor: pointer;

  &:hover {
    @include attribute(background-color, secondary-dark, darken, 5);
  }

  .span {
    @include pl(xs);
  }
}

@media print {
  .overlay {
    height: auto;
    overflow-y: initial;
  }

  .overlay__fallback {
    height: auto;
  }
}
