label {
  @extend %text;

  @include m(0);

  display: block;

  @include attribute(color, primary-light);

  position: relative;
  word-break: break-word;

  &.label--right {
    text-align: right;

    &.label--floating {
      float: right;
    }
  }

  &.label--clickable {
    cursor: pointer;

    &:hover {
      @include attribute(color, primary, rgba, 0.7);
    }
  }

  &.label--inline {
    display: inline-block;
  }

  .label__hint {
    position: relative;
    display: inline-block;
    vertical-align: top;

    ::ng-deep [hint--left] .hint {
      left: -17px !important; // sorry
      top: 150%;
    }
  }
}

label,
.label__value {
  @include p(xs, 0);

  @extend %line-height--text;
}

.label__value {
  @include m(0);

  display: block;

  @include attribute(color, primary-dark);

  &--pre-wrapped {
    white-space: pre-wrap;
  }

  &.label__value--link {
    cursor: pointer;

    @include attribute(color, secondary-dark);

    text-decoration: underline;

    &:focus {
      outline: none;
    }
  }

  &.new-line {
    clear: both;
  }
}
