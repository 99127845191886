button {
  border-spacing: 0;
  outline: none;
  appearance: none;

  &[disabled] {
    pointer-events: none;
  }
}

button::-moz-focus-inner {
  @include p(0);

  border: 0;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}
