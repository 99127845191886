p-tristatecheckbox {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.p-checkbox {
  .p-checkbox-box {
    width: 12px;
    height: 12px;
    position: relative;
    border: 1px solid;
    border-radius: 2px;

    @include attribute(border-color, primary);

    &.p-disabled {
      opacity: 0.5 !important;
    }

    .p-checkbox-icon {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid;

      @include attribute(border-color, primary);
    }

    &.p-highlight {
      background-color: unset;
      border: 0;


      .p-checkbox-icon {
        border: 0;

        @include attribute(background-color, secondary);

        &::before {
          content: "";
          display: block;
          border-style: solid;
          position: relative;

          @include attribute(border-color, white);
        }

        &.pi-check {
          &::before {
            transform: rotate(45deg);
            border-width: 0 1px 1px 0;
            width: 3px;
            height: 5px;
            top: 2px;
            left: 4px;
          }
        }

        &.pi-times {
          &::before {
            border-width: 0 0 1px;
            width: 4px;
            height: 6px;
            left: 4px;
          }
        }
      }
    }
  }

  + .p-checkbox-label {
    @include p(0);

    cursor: pointer;
    @extend %line-height--text;

    // Note: This was set to inline, because otherwise long labels jump into the next line
    display: inline;
  }
}
