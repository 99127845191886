.trend {
  @include ml(xs);

  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: relative;

  &.trend--neutral {
    @include attribute(border-color, signal);

    transform: rotateZ(45deg);
    top: -1px;
  }

  &.trend--positive {
    @include attribute(border-color, success);

    transform: rotateZ(-45deg);
    top: 1px;
  }

  &.trend--negativ {
    @include attribute(border-color, fail);

    transform: rotateZ(135deg);
    top: -3px;
  }
}
