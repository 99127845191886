h1 {
  @extend %heading;

  @include m(0);

  @extend %heading--xlarge;
}

h2 {
  @extend %heading;

  @include m(0);

  @extend %heading-chart--large;
}

h3 {
  @extend %heading;

  @include m(0);

  @extend %heading--large;
}

h4 {
  @extend %heading--large;

  @include mt(l);
  @include mb(xl);
}

h5 {
  @extend %heading;

  @include mt(0);
}

h6 {
  @extend %heading--small;

  @include m(0, 0, m, 0);
}

a {
  text-decoration: underline;

  @include attribute(color, secondary-dark);

  cursor: pointer;
}

hr {
  @include clearfix;

  clear: both;
  border: dashed 1px;

  @include attribute(border-color, grey);

  border-bottom: 0;
  height: 1px;

  &.more-margin {
    @include m(l, 0);
  }
}

.infotext {
  @include mb(s);

  @extend %text;

  @include attribute(color, grey);

  overflow: hidden;
}
