$item-active-border-width: 3px;
$item-line-height: 50px;
$item-line-height-plain: 30px;

.list-menu {
  @include m(0);

  position: absolute;
  z-index: 51;
  top: 68px;
  left: 0;
  max-width: 400px;

  &--wide {
    width: 400px;
  }

  &::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;

    @include attribute(background-color, white);

    top: -9px;
    left: 100px;
    box-shadow: 2px 1px 4px 0 rgba(0, 0, 0, 20%);
    z-index: 1;
  }

  &__wrapper {
    @include p(xs, 0);

    position: relative;
    z-index: 2;

    @include attribute(background-color, white);

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 20%);
  }

  &__header {
    @include p(m, l);

    @extend %text;
    @extend %text--strong;

    @include attribute(color, primary-dark);
  }

  &__item {
    @include p(0, xxl, l);

    cursor: pointer;
    width: 100%;
    overflow: hidden;
    position: relative;
    min-width: 300px;

    @include attribute(color, primary-dark);

    white-space: nowrap;
    height: $item-line-height;

    @include clearfix;

    .icon,
    .label {
      display: inline-block;
    }

    .label {
      @extend %text;
      @extend %text--strong;

      overflow: hidden;
      width: 80%;
      max-width: 500px;
      text-overflow: ellipsis;
      white-space: nowrap;

      &__subtitle {
        @include ml(xs);

        @extend %text;

        @include attribute(color, primary-light);
      }
    }

    &--plain {
      height: $item-line-height-plain;

      .list-menu__count,
      .list-menu__module {
        top: ($item-line-height-plain - 18px) * 0.5; // 18px = element height
      }
    }

    &--stacked {
      .label {
        @include p(xs, 0);

        .label__subtitle {
          display: block;
        }
      }
    }

    &--active {
      border-right: $item-active-border-width solid;

      @include attribute(border-right-color, secondary);
      @include attribute(background-color, white-almost);

      .list-menu__module {
        margin-right: -$item-active-border-width;
      }
    }

    @include sweep(secondary-light);
  }

  &__backdrop {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
  }

  &__module,
  &__count {
    @include p(xs);

    position: absolute;
    top: ($item-line-height - 18px) * 0.5; // 18px = element height
    right: 25px;
    border-radius: 100px;
    min-width: 30px;
    text-align: center;
    @extend %text--small;
    @include attribute(color, white);
    @include attribute(background-color, secondary);

    text-transform: uppercase;
  }

  &__module {
    @include attribute(background-color, primary-light);
    @include attribute(color, white);
  }
}
