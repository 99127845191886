.window {
  $root: &;

  z-index: -1;

  &#{&}--open {
    #{$root}__container {
      transform: translate(0, 0);
    }

    #{$root}__backdrop {
      opacity: 0.5;
      visibility: visible;
      transition: opacity 0.3s ease-in-out 0s, visibility 0.3s linear 0s;
    }
  }

  #{&}__container {
    transition: transform 0.3s ease-in-out;

    @include attribute(background-color, white);
    @include shadow;

    transform: translate(-110%, 0);
    position: absolute;
    bottom: 0;

    #{$root}__header {
      @include m(0);
      @include p(l, xxl, l, xl);
      @include attribute(color, white-static);
      @include attribute(background-color, primary-static);
      @include attribute-overwrite(background-color, ( dark: primary-dark-static ));

      &--light {
        background-color: transparent;

        @include attribute(color, primary-static);
      }
    }

    #{$root}__content {
      @include p(l, xl);
    }

    #{$root}__close {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;

      &:hover {
        @include attribute(color, secondary);
      }
    }
  }

  #{&}__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out 0s, visibility 0.3s linear 0.3s;

    @include attribute(background-color, backdrop-dark);
  }
}
