@import "mixins";

// disable transform for t23-icons in reset.scss for animation to work
t23-icon.animate {
  transform: none;
}

.animate {
  &.blink {
    @include animate-blink;
  }

  &.loading {
    @include animate-loading("span");
  }

  &.spin {
    @include animate-spin;
  }

  &.fade-in {
    @include animate-fade-in;
  }

  &.fade-out {
    @include animate-fade-out;
  }
}
