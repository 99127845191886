.tag {
  $root: &;

  @include p(0, m);

  border-radius: 12px;

  @include attribute(background-color, white);

  cursor: pointer;

  &#{&}--active {
    @include attribute(background-color, secondary-light);

    #{$root}__description {
      @include attribute(color, white);
    }
  }

  &#{&}--archived {
    @include attribute(background-color, primary-light);

    &#{$root}--active {
      @include attribute(background-color, primary-light);
    }
  }

  #{&}__header {
    @include pr(xs);

    @extend %text;
    @extend %text--strong;

    @include attribute(color, primary);
  }

  #{&}__description {
    @include attribute(color, primary-light);
  }
}
