@mixin animate-fade-out($time: 0.3s, $delay: 0s, $transitions: 0) {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @if $transitions != 0 {
    transition: opacity $time ease-in-out $delay, visibility 0s linear $time + $delay, $transitions;
  } @else {
    transition: opacity $time ease-in-out $delay, visibility 0s linear $time + $delay;
  }
}

@mixin animate-fade-in($time: 0.3s, $delay: 0s, $transitions: 0) {
  opacity: 1;
  visibility: visible;
  pointer-events: all;

  @if $transitions != 0 {
    transition: opacity $time ease-in-out $delay, visibility 0s linear $delay, $transitions;
  } @else {
    transition: opacity $time ease-in-out $delay, visibility 0s linear $delay;
  }
}
