@use "sass:list";

$background-map: (primary, white),
  (secondary, white),
  (quarternary, white),
  (grey-light, white);

.background {
  @each $map in $background-map {
    $background: list.nth($map, 1);
    $color: list.nth($map, 2);

    &--#{$background} {
      @include attribute(background-color, $background);
      @include attribute(color, $color);
    }
  }
}

$text-colors: fail, success, signal, warning, secondary, quarternary, primary, white-static;

.text,
.heading {
  @each $color in $text-colors {
    &--#{$color} {
      @include attribute(color, $color);
    }
  }
}

.temperate {
  opacity: 0.5;
}
