.search-field {
  @include mr(s);

  height: 38px;
  width: 60%;
  max-width: 648px;
  border: 0;
  box-shadow: none;
}

.filter-input-box {
  position: relative;

  input {
    @extend %text;

    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid;

    @include attribute(color, black-almost);
    @include attribute(background-color, white);
    @include attribute(border-bottom-color, grey-light);
  }

  &::after {
    display: block;
    position: absolute;
    width: 18px;
    height: 100%;
    right: 0;
    top: 0;
    content: "";
    background: url("/assets/img/icons/active/search-active.svg") no-repeat center;
    background-size: contain;
  }

  &.filter-input-box--large {
    input {
      @include p(m, s, m, xxl);

      border: none;
    }

    &::after {
      display: block !important;
      right: auto;
      left: 10px;
    }
  }

  &.filter-input-box--with-button {
    display: flex;
    align-items: stretch;

    input {
      flex-grow: 1;
    }

    button {
      @extend %text;

      @include m(0);

      min-width: 100px;

      &[disabled] {
        @include temperate;
      }
    }

    &::after {
      display: none;
    }
  }
}
