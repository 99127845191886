@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@mixin animate-blink($time: 1.4s) {
  animation-name: blink;
  animation-duration: $time;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
