.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .page__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: visible;
  }

  .page__wrapper {
    height: 100%;
    padding-top: $navbar-height;
  }
}

.page__wrapper {
  overflow: hidden;
}

.page__sidebar-left {
  display: flex;
  position: relative;
  z-index: 2;
}

.page__content {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.page__padding {
  @include p(xxl);

  display: block;

  /* No margin on first headline */
  > h4:first-child {
    @include mt(0);
  }
}

.page__content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .page-scroll {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.page--scrollable {
      overflow-y: auto;
    }
  }
}
