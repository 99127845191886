.field {
  @include m(xs, 0);

  display: flex;
  width: 100%;
  position: relative;

  &__label {
    flex: 1;
    word-break: break-all;
  }

  &__value {
    flex: 2;
    word-break: break-all;

    > pro-checkbox {
      @include p(s, 0, s, s);

      top: 1px;
    }

    .field__text {
      @include value-spacing;
      @include pr(0);

      display: inline-block;
    }
  }

  label {
    @include value-spacing;
    @include pl(0);

    t23-icon {
      @include ml(xs);

      position: absolute;
    }
  }
}
