/* stylelint-disable selector-max-id */
#list-navigation {
  @include p(s, xxl);

  @media only screen and (width <= 1124px) {
    height: 60px;
  }

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .list-entries-wrapper,
  .p-paginator-wrapper {
    @include m(0);

    display: flex;
    align-items: center;

    .ui-paginator-page:not(.p-highlight),
    .list-entry:not(.active) a,
    .label {
      @include attribute(color, primary-dark);
    }

    .ui-paginator-page.p-highlight {
      @include attribute(color, secondary);
    }

    .p-paginator-pages,
    .p-paginator-pages .p-paginator-page,
    .p-paginator-prev,
    .p-paginator-next {
      background: transparent;
      box-shadow: none;
    }

    .p-paginator-pages {
      @include p(0);
    }

    .p-paginator-prev {
      @include mr(xs);
    }

    .p-paginator-next {
      @include ml(xs);
    }

    p-paginator {
      .p-paginator-prev,
      .p-paginator-next {
        display: none;
      }
    }
  }
}

.p-paginator-wrapper {
  @include m(s, xs, 0, 0);

  float: right;

  // TODO: move to general-search-component
  &.general-search-paginator {
    text-align: right;

    .p-paginator {
      text-align: right;

      .p-paginator-prev,
      .p-paginator-next {
        @include p(0, l);

        background: none;
        float: none;
      }
    }
  }
}

.p-paginator {
  @include p(0);

  text-align: center;
  display: block;
  width: auto;
  background: transparent;
  border: none;

  .p-paginator-top {
    border-bottom: 0 none;
  }

  .p-paginator-bottom {
    border-top: 0 none;
  }

  .p-paginator-last,
  .p-paginator-first {
    display: none;
  }

  .p-paginator-prev,
  .p-paginator-next {
    @include m(0);
    @include p(0);

    float: left;
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    min-width: auto;
    cursor: pointer;
    position: relative;
    text-shadow: none;
    outline: none;
    background: transparent;

    @include attribute(color, primary);

    &:hover {
      @include attribute(color, secondary);
    }
  }

  .p-paginator-page,
  .p-paginator-current {
    @include m(0);
    @include p(0, xs);

    display: flex;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius: 0;

    @include box-shadow($color-black #000);

    text-shadow: none;
    outline: none;
    opacity: 1;
    float: left;
    height: 30px;
    min-width: 30px;

    @include attribute(color, primary);

    background: transparent;
    vertical-align: middle;
    text-align: center;
    position: relative;

    &:hover {
      @include attribute(color, secondary);
    }

    &.p-highlight {
      @include attribute(color, secondary);
    }

    &.p-disabled {
      opacity: 0.3;

      &:hover {
        @include attribute(background-color, white);
      }
    }
  }

  .p-paginator-pages {
    @include m(0);
    @include p(0);

    float: left;
  }
}
