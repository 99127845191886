/**
 *  Default TEAM23 Theme
 */

/* Spacing */

$spacing-base: 10px;
$spacing-xs: 0.5 * $spacing-base;
$spacing-s: $spacing-base;
$spacing-m: 1.5 * $spacing-base;
$spacing-l: 2 * $spacing-base;
$spacing-xl: 3 * $spacing-base;
$spacing-xxl: 4 * $spacing-base;

/* Theming */
$theme-hover-color-correction: 10;

/** Form Elements */
$navbar-height: 60px;

/** Input Dimensions */
$input-line-height: 17px; // normal line height when font-size is 12px;
$input-spacing: $spacing-s;
$input-height: $input-line-height + 2 * $input-spacing;
