.p-dialog.p-confirm-dialog {
  @include box-shadow-dropdown;
  @include p(xxl);

  $dialog-inner-padding: 140px;

  padding-top: $dialog-inner-padding;
  background: $color-primary;
  color: $color-white;
  width: 460px;
  display: flex;

  .p-dialog-header-icons {
    display: none;
  }

  .p-dialog-header {
    position: relative;
    text-align: center;
    justify-content: center;
  }

  .p-dialog-title {
    @extend %heading--large;

    color: $color-white;
    text-transform: uppercase;

    &::before {
      content: " ";
      width: 50px;
      height: 50px;
      position: absolute;
      top: -55px;
      left: 50%;
      background: url("/assets/img/icons/white/error_circle-white.svg") no-repeat;
      background-size: 50px 50px;
      transform: translate(-50%, -50%);
    }
  }

  .p-dialog-content {
    @include p(l);

    justify-content: center;
  }

  .p-confirm-dialog-message {
    text-align: center;
  }

  .p-dialog-footer {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .p-icon-wrapper {
    padding-inline-start: 10px;
  }

  button,
  .pro-button {
    @include m(xs);
  }

  // @deprecated
  button {
    @extend %text;

    @include p(0);

    display: flex;
    float: none;
    min-width: auto;
    border-radius: 0;
    border: 1px solid $color-white;
    color: $color-white;
    background: none;
    text-shadow: none;

    &:hover {
      @include attribute(background-color, primary);
    }

    .p-button-label {
      @include p(xs, m, xs, xl);

      display: inline-block;
      padding-inline-start: 20px;
    }

    .pi {
      position: absolute;
      top: 50%;
      margin-top: -0.5em;
      left: 0.5em;
    }

    .pi-times {
      background: url("/assets/img/icons/white/schliessen-white.svg") no-repeat;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      margin-left: 2px;

      &::before {
        content: " ";
      }
    }

    .pi-check {
      background: url("/assets/img/icons/white/speichern-white.svg") no-repeat;
      background-size: 12px 12px;
      width: 12px;
      height: 12px;
      margin-left: 2px;

      &::before {
        content: " ";
      }
    }
  }
}
