@mixin input() {
  @extend %text;

  padding: $input-spacing;
  border: 0;
  border-radius: 0;

  @include attribute(background-color, grey-light);
  @include attribute(color, primary);

  &:hover {
    @include attribute(background-color, grey-light, lighten, 3);
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    @extend %text;

    @include attribute(color, grey-dark);

    @media print {
      font-size: 16px;
    }
  }
}

@mixin input-validation() {
  @include element-border;

  &.ng-touched {
    &.ng-valid {
      @include element-border-active(success);
    }

    &.ng-invalid {
      @include element-border-active(fail);
    }
  }

  &.focused {
    @include element-border-active(primary);
  }
}
