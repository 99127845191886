.clickable,
.pointer {
  cursor: pointer;

  &:hover {
    filter: brightness(0.85);
  }

  &:focus {
    outline: none;
  }
}
