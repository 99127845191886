.p-toast.p-toast-top-right {
  width: 350px;
  right: 20px;
  top: 80px;

  .p-toast-message {
    @include box-shadow-dropdown;
    @include mb(xs);

    $toast-size-icon: 30px;

    padding-left: $toast-size-icon + 2 * $spacing-m;

    @include attribute(color, primary);
    @include attribute(background-color, white);

    position: relative;

    .p-toast-message-content {
      @include p(s, xl, s, 0);
    }

    .p-toast-message-text {
      @include m(xs, 0);
    }

    .p-toast-summary {
      @extend %text--strong;
      @extend %text--large;

      @include pb(xs);
    }

    .p-toast-message-icon {
      @include m(0);

      background-size: 30px;
      width: 30px;
      height: 30px;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translate(0, -50%);

      &::before {
        content: " ";
      }
    }

    .p-toast-icon-close {
      top: 8px;
      right: 8px;
      position: absolute;
    }

    .p-toast-icon-close-icon {
      background: url("/assets/img/icons/dark/schliessen-dark.svg") no-repeat;
      background-size: 14px 14px;
      width: 14px;
      height: 14px;

      &::before {
        content: " ";
      }
    }
  }
}

.p-toast-message {
  border-left: 3px solid;

  // Remove the native icon and custom icon
  .p-element.p-icon-wrapper {
    display: none;
  }

  &.p-toast-message {
    &-success {
      @include attribute(border-left-color, success);

      .p-toast-message-icon {
        background: url("/assets/img/icons/color/check_circle-color.svg") no-repeat;
      }
    }

    &-error {
      @include attribute(border-left-color, fail);

      .p-toast-message-icon {
        background: url("/assets/img/icons/color/error_circle-color.svg") no-repeat;
      }
    }

    &-info {
      @include attribute(border-left-color, primary);

      .p-toast-message-icon {
        background: url("/assets/img/loading.gif") no-repeat;
        background-size: 30px 30px;
      }
    }
  }
}
