@use "sass:color";
@use "sass:meta";
@use "sass:map";

$theme-default: "light";
$theme-classes: (
  "dark": ".theme-dark",
  "light": ".theme-light",
);

$theme-colors: (
  "primary": (
    "light": $color-primary,
    "dark": $color-white,
  ),
  "primary-static": (
    "light": $color-primary,
    "dark": $color-secondary,
  ),
  "primary-opaque": (
    "light": rgba($color-primary, 0.7),
    "dark": rgba($color-white, 0.7),
  ),
  "primary-light": (
    "light": $color-primary-light,
    "dark": $color-grey,
  ),
  "primary-light-static": (
    "light": $color-primary-light,
    "dark": color.adjust($color-secondary-dark, $lightness: -5%),
  ),
  "primary-light-opaque": (
    "light": rgba($color-primary-light, 0.3),
    "dark": rgba($color-grey, 0.3),
  ),
  "primary-dark": (
    "light": $color-primary-dark,
    "dark": color.adjust($color-white, $lightness: -5%),
  ),
  "primary-dark-static": (
    "light": $color-primary-dark,
    "dark": #374c57,
  ),
  "secondary": (
    "light": $color-secondary,
    "dark": $color-secondary,
  ),
  "secondary-light": (
    "light": $color-secondary-light,
    "dark": #374c57,
  ),
  "secondary-dark": (
    "light": $color-secondary-dark,
    "dark": #3ec2cf,
  ),
  "tertiary": (
    "light": $color-tertiary,
    "dark": $color-tertiary,
  ),
  "tertiary-dark": (
    "light": $color-tertiary-dark,
    "dark": $color-tertiary-dark,
  ),
  "quarternary": (
    "light": $color-quarternary,
    "dark": $color-quarternary,
  ),
  "fail": (
    "light": $color-fail,
    "dark": $color-fail,
  ),
  "fail-light": (
    "light": $color-fail-light,
    "dark": #49323a,
  ),
  "success": (
    "light": $color-success,
    "dark": $color-success,
  ),
  "success-light": (
    "light": $color-success-light,
    "dark": #344739,
  ),
  "signal": (
    "light": $color-signal,
    "dark": $color-signal,
  ),
  "warning": (
    "light": $color-warning,
    "dark": $color-warning,
  ),
  "white": (
    "light": $color-white,
    "dark": #373b45,
  ),
  "white-static": (
    "light": $color-white,
    "dark": $color-white,
  ),
  "white-almost": (
    "light": $color-white-almost,
    "dark": #25282e,
  ),
  "disabled-icon": (
    "light": #bbb,
    "dark": #bbb,
  ),
  "disabled-text": (
    "light": #888,
    "dark": #888,
  ),
  "grey-light": (
    "light": $color-grey-light,
    "dark": $color-grey-dark,
  ),
  "grey": (
    "light": $color-grey,
    "dark": #25282e,
  ),
  "grey-static": (
    "light": $color-grey,
    "dark": $color-grey,
  ),
  "grey-dark": (
    "light": $color-grey-dark,
    "dark": $color-grey-light,
  ),
  "black-almost": (
    "light": $color-black-almost,
    "dark": color.adjust($color-white, $lightness: -5%),
  ),
  "black": (
    "light": $color-black,
    "dark": $color-white,
  ),
  "backdrop": (
    "light": rgba($color-primary, 0.5),
    "dark": rgba($color-primary, 0.5),
  ),
  "backdrop-dark": (
    "light": rgba($color-primary-dark, 0.9),
    "dark": rgba($color-secondary, 0.5),
  ),
);

// get theme from colors map

@function get-theme-colors($key) {
  $theme: map.get($theme-colors, "" + $key);

  @if $theme {
    @return $theme;
  }

  @error "Missing Theme Color for #{$key} in $theme-colors.";
}

// applies attribute styling for all available themes at root level (.context.theme-class)
@mixin root-theme($attribute, $colorKey) {
  @each $name, $color in get-theme-colors($colorKey) {
    @if $name == $theme-default {
      #{$attribute}: $color;
    }

    &#{map-get($theme-classes, '' + $name)} {
      #{$attribute}: $color;
    }
  }
}

// applies theme styling for selector

// throws error when the selected theme is not define in $theme-classes

// usage:

// @include theme(dark) {

//   color: red;

// }

@mixin theme($selector: $theme-default) {
  @if $selector == $theme-default {
    @content;
  } @else {
    $theme: map.get($theme-classes, "" + $selector);

    @if $theme {
      @if $theme-context == $theme-context-global {
        @include create-context(#{$theme}) {
          @content;
        }
      } @else if $theme-context == $theme-context-host {
        @include create-host-context(#{$theme}) {
          @content;
        }
      } @else {
        @error " Unhandled Theme Context #{$theme-context} in abstract/theme.";
      }
    } @else {
      @error "Missing Theme #{$selector} in $theme-classes.";
    }
  }
}

// defines attribute explicitly

// allows overwriting of theme

// explicit usage:

// @include attribute-overwrite(color, ( dark: primary ));

@mixin attribute-overwrite($attribute, $theme: (), $modifier: null, $amount: 10) {
  @each $name, $colorKey in $theme {
    $color: map.get(get-theme-colors($colorKey), $name);

    @if $color {
      @include apply-theme($name, $attribute, $color, $modifier, $amount);
    } @else {
      @error "Missing Theme #{$name} for Color #{$colorKey}.";
    }
  }
}

// defined the given color attribute

// uses map to get all colors for all themes

// allows to modify color with modifier function (darken, lighten, etc)

// allows to change amount of modification (default is 10)

// usage:

// @include attribute(color, primary);

// @include attribute(color, primary, darken);

// @include attribute(color, primary, lighten, 10);

@mixin attribute($attribute, $colorKey, $modifier: null, $amount: $theme-hover-color-correction) {
  @each $name, $color in get-theme-colors($colorKey) {
    @include apply-theme($name, $attribute, $color, $modifier, $amount);
  }
}

// helper function to apply argument with optional modifier
@mixin apply-attribute($attribute, $color, $modifier: null, $amount: $theme-hover-color-correction) {
  @if $modifier {
    #{$attribute}: meta.call(meta.get-function($modifier), $color, $amount);
  } @else {
    #{$attribute}: $color;
  }
}

// helper function to apply theme with argument with optional modifier
@mixin apply-theme($name, $attribute, $color, $modifier: null, $amount: $theme-hover-color-correction) {
  @include theme($name) {
    @include apply-attribute($attribute, $color, $modifier, $amount);
  }
}
