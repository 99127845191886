/**
 * Some styling for Drag'n'Drop preview
 * unfortunately this has to be done 'globally' for cdk-drag-preview
 * (no wrapping within main-list possible)
 */

.cdk-drag-preview {
  @include p(s);

  overflow: hidden;
  @extend %text--strong;

  white-space: nowrap;
  background: $color-secondary-light;
  opacity: 0.5;

  t23-icon {
    display: none;
  }

  pro-column-options {
    display: none;
  }
}

.main-list {
  .main-list__cell--header {
    &.cdk-drag-placeholder {
      background: $color-secondary-light;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba($color-primary, 0.15);
}
