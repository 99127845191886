/* Datepicker (copied from saga-blue theme) */
/* stylelint-disable selector-max-compound-selectors */
.p-datepicker {
  $size-col: $spacing-xl;

  @include attribute(background-color, white);
  @include attribute(color, primary);
  @include attribute(color, primary);

  min-width: auto !important;
  width: auto !important;
  border: 1px solid;
  border-radius: 3px;

  @include attribute(border-color, grey);

  &:not(.p-datepicker-inline) {
    @include attribute(background-color, white);

    border: 0 none;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 20%),
      0 4px 5px 0 rgba(0, 0, 0, 14%),
      0 1px 10px 0 rgba(0, 0, 0, 12%);

    .p-datepicker-header {
      @include attribute(background-color, white);
    }
  }

  .p-datepicker-header {
    @include p(xs);
    @include attribute(color, primary);
    @include attribute(background-color, white);

    @extend %heading--strong;

    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom: 1px solid;

    @include attribute(border-bottom-color, grey-light);

    .p-datepicker-prev {
      @include attribute(color, primary);

      width: $spacing-l;
      height: $spacing-l;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled {
        &:hover {
          @include attribute(color, primary);
          @include attribute(background-color, grey-light);

          border-color: transparent;
        }
      }
    }

    .p-datepicker-next {
      @include attribute(color, primary);

      width: $spacing-l;
      height: $spacing-l;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled {
        &:hover {
          @include attribute(color, primary);
          @include attribute(background-color, grey-light);

          border-color: transparent;
        }
      }
    }

    .p-datepicker-title {
      .p-datepicker-year {
        @include attribute(color, primary);
        @include p(s);

        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        @extend %text--strong;

        &:enabled {
          &:hover {
            @include attribute(color, secondary);
          }
        }
      }

      .p-datepicker-month {
        @include attribute(color, primary);
        @include p(s);
        @include mr(s);

        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        @extend %text--strong;

        &:enabled {
          &:hover {
            @include attribute(color, secondary);
          }
        }
      }
    }
  }

  table {
    @include m(0);

    tr:hover {
      background-color: transparent;
    }

    th {
      @include p(0);

      border: none;
      text-align: center;

      > span {
        width: $size-col;
        height: $size-col;
      }
    }

    td {
      @include p(0);

      border: none;
      width: $size-col + $spacing-xs;
      height: $size-col + $spacing-xs;

      > span {
        width: $size-col;
        height: $size-col;
        border-radius: 50%;
        transition: box-shadow 0.2s;
        border: 1px solid transparent;
      }

      > span.p-highlight {
        @include attribute(color, primary);
        @include attribute(background-color, secondary-light);
      }
    }

    td.p-datepicker-today {
      > span {
        background: #ced4da;
        @include attribute(color, primary);

        border-color: transparent;
      }

      > span.p-highlight {
        @include attribute(color, primary);
        @include attribute(background-color, secondary-light);
      }
    }
  }

  .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid;

    @include attribute(border-top-color, grey-light);

    .p-button {
      width: auto;
    }
  }

  .p-timepicker {
    border-top: 1px solid;

    @include attribute(border-top-color, grey-light);
    @include p(xs);

    button {
      @include attribute(color, primary);

      width: $spacing-l;
      height: $spacing-l;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

      &:enabled {
        &:hover {
          @include attribute(color, primary);
          @include attribute(background-color, grey-light);

          border-color: transparent;
        }
      }
    }

    > div {
      @include px(s);
      @include py(0);
    }
  }

  .p-monthpicker {
    @include my(s);
    @include mx(0);

    .p-monthpicker-month {
      @include p(s);

      transition: box-shadow 0.2s;
      border-radius: 3px;
    }

    .p-monthpicker-month.p-highlight {
      @include attribute(color, primary);
      @include attribute(background-color, secondary-light);
    }
  }

  .p-yearpicker {
    @include my(s);
    @include mx(0);

    .p-yearpicker-year {
      @include p(s);

      transition: box-shadow 0.2s;
      border-radius: 3px;
    }

    .p-yearpicker-year.p-highlight {
      @include attribute(color, primary);
      @include attribute(background-color, secondary-light);
    }
  }

  .p-disabled {
    @include temperate;
  }

  &:not(.p-disabled) {
    table {
      td {
        span {
          &:not(.p-highlight) {
            &:not(.p-disabled) {
              &:hover {
                @include attribute(background-color, grey-light);
              }
            }
          }
        }
      }
    }

    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          &:not(.p-highlight) {
            &:hover {
              @include attribute(background-color, grey-light);
            }
          }
        }
      }
    }

    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          &:not(.p-highlight) {
            &:hover {
              @include attribute(background-color, grey-light);
            }
          }
        }
      }
    }
  }
}

.p-datepicker.p-datepicker-timeonly {
  .p-timepicker {
    border-top: 0 none;
  }
}

.p-datepicker.p-datepicker-multiple-month {
  .p-datepicker-group {
    border-left: 1px solid;

    @include attribute(border-left-color, grey-light);
    @include px(s);
    @include py(0);

    &:first-child {
      padding-left: 0;
      border-left: 0 none;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
