@import "animate-blink";

@mixin animate-loading($selector: "*") {
  > #{$selector} {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  $children: 2, 3, 4, 5, 6;

  @each $child in $children {
    > #{$selector}:nth-child(#{$child}) {
      animation-delay: #{0.2 * ($child - 1)}s;
    }
  }
}
