@use "sass:map";

$spacing-sizes: (
  0: 0,
  auto: auto,
  xs: $spacing-base * 0.5,
  s: $spacing-base,
  m: $spacing-base * 1.5,
  l: $spacing-base * 2,
  xl: $spacing-base * 3,
  xxl: $spacing-base * 4,
);

$spacing-directions: (
  l: left,
  r: right,
  t: top,
  b: bottom,
);

$spacing-rules: (
  m: margin,
  p: padding,
);

@mixin margin($d, $s) {
  margin-#{map.get($spacing-directions, $d)}: map.get($spacing-sizes, $s);
}

@mixin m($t, $r: "", $b: "", $l: "") {
  margin:
    map.get($spacing-sizes, $t)
    map.get($spacing-sizes, $r)
    map.get($spacing-sizes, $b)
    map.get($spacing-sizes, $l);
}

@mixin ml($s) {
  @include margin(l, $s);
}

@mixin mr($s) {
  @include margin(r, $s);
}

@mixin mt($s) {
  @include margin(t, $s);
}

@mixin mb($s) {
  @include margin(b, $s);
}

@mixin mx($s) {
  margin-left: map.get($spacing-sizes, $s);
  margin-right: map.get($spacing-sizes, $s);
}

@mixin my($s) {
  margin-top: map.get($spacing-sizes, $s);
  margin-bottom: map.get($spacing-sizes, $s);
}

@mixin padding($d, $s) {
  padding-#{map.get($spacing-directions, $d)}: map.get($spacing-sizes, $s);
}

@mixin p($t, $r: "", $b: "", $l: "") {
  padding:
    map.get($spacing-sizes, $t)
    map.get($spacing-sizes, $r)
    map.get($spacing-sizes, $b)
    map.get($spacing-sizes, $l);
}

@mixin pl($s) {
  @include padding(l, $s);
}

@mixin pr($s) {
  @include padding(r, $s);
}

@mixin pt($s) {
  @include padding(t, $s);
}

@mixin pb($s) {
  @include padding(b, $s);
}

@mixin px($s) {
  padding-left: map.get($spacing-sizes, $s);
  padding-right: map.get($spacing-sizes, $s);
}

@mixin py($s) {
  padding-top: map.get($spacing-sizes, $s);
  padding-bottom: map.get($spacing-sizes, $s);
}

@function get-spacing($s) {
  @return map.get($spacing-sizes, $s);
}
