.number-value,
.text-value {
  @extend %text;

  @include ml(s);
  @include p(0, s);
  @include attribute(background-color, white-almost);

  border-radius: 100px;
  min-width: 30px;
  display: inline-block;
  text-align: center;
}

.number-value__secondary,
.text-value__secondary {
  @include attribute(background-color, secondary);
  @include attribute(color, white-static);

  &--disabled {
    @include attribute(background-color, grey);
  }
}
