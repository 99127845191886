.rel,
.relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.text,
.heading {
  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }
}
