/* used for pInputText directive */
.p-inputtext {
  @extend %text;

  padding: $input-spacing;
  height: $input-height;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 0;

  @include attribute(background-color, grey-light);
  @include attribute(color, primary);

  &.p-input--symbol {
    @include pr(xxl);
  }

  &.p-disabled {
    opacity: 1;
  }
}

textarea.p-inputtext {
  display: block;
  height: auto;
}

.p-inputgroup .p-inputgroup-addon {
  @include p(xs);

  height: 35px;
  border: none;
  cursor: default;
  border-right: 1px solid;

  @include attribute(border-right-color, grey);
  @include attribute(color, grey-dark);
  @include attribute(background-color, white-almost);
}
