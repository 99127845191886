@mixin brick() {
  @include m(s);
  @include p(xl);
  @include attribute(background-color, white);

  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 10%);
  border-left: 3px solid;
  overflow: hidden;

  @include attribute(border-left-color, primary-light);

  @media (width <= 1200px) {
    width: calc(50% - 20px);
  }

  @media (width >= 1200px) {
    width: calc(33% - 20px);
  }
}

@mixin brick-info() {
  @include mb(xs);

  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.2px;

  @include attribute(color, primary-light);
}

@mixin brick-title() {
  @include mb(xs);

  @extend %text--large;
  @extend %text--strong;

  line-height: 1.43;
  letter-spacing: 0.3px;

  @include attribute(color, primary);
}

@mixin brick-subtitle() {
  @include mb(m);

  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.2px;

  @include attribute(color, primary-light);
}

@mixin brick-content() {
  @extend %text;

  @include mt(s);
  @include attribute(color, primary-dark);

  p {
    @extend %text;
  }
}

@mixin brick-image() {
  @include aspect-ratio(416, 248);

  margin: 30px -30px 0 -27px;
  top: -30px;
  overflow: hidden;

  > * {
    width: 100%;
  }
}

@mixin brick-download() {
  &::before {
    @include mr(xs);

    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: transparent url("/assets/img/icons/active/download-active.svg") no-repeat center center;
    background-size: 100%;
    float: left;
    margin-top: 3px;
  }

  @include mt(m);

  display: block;
  @extend %text;
  @extend %text--strong;

  @include attribute(color, secondary);

  line-height: 24px;
  text-decoration: none;

  &:hover {
    @include attribute(color, primary);
  }
}

@mixin brick-download-all {
  &::before {
    @include mr(xs);

    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background: transparent url("/assets/img/icons/dark/zip-dark.svg") no-repeat center center;
    background-size: 100%;
    float: left;
  }

  @include pt(m);
  @include mt(m);
  @include attribute(color, primary-dark);

  border-top: 1px solid;

  @include attribute(border-top-color, primary-dark, rgba, 0.2);

  cursor: pointer;
  font-size: 12px;

  &:hover {
    opacity: 0.7;
  }
}

@mixin brick-text {
  @extend %text;

  @include m(m, 0);
  @include pb(m);

  border-bottom: 1px solid;

  @include attribute(border-bottom-color, primary-dark, rgba, 0.2);
  @include attribute(color, primary-dark);
}

.brick {
  @include brick;

  &--download {
    @include attribute(border-color, secondary);
  }

  &__info {
    @include brick-info;
  }

  &__title {
    @include brick-title;

    &--inactive {
      @include attribute(color, primary-light);
    }
  }

  &__subtitle {
    @include brick-subtitle;
  }

  &__text {
    @include brick-text;
  }

  &__content {
    @include brick-content;
  }

  &__download {
    @include brick-download;
  }

  &__image {
    @include brick-image;
  }

  &__download-all {
    @include brick-download-all;
  }

  &__button {
    @include mr(s);

    width: 110px;
    border: 1px solid;

    @include attribute(border-color, primary);

    @extend %line-height--text;

    @include attribute(color, primary);
    @include sweep(secondary-light);

    opacity: 0.7;
    text-align: center;
    display: inline-block;
    text-decoration: none;

    > * {
      position: relative;
      z-index: 89;
    }
  }

  p {
    overflow: hidden;
  }
}

#masonry {
  // Backend Only: for Prospitalia to style their text
  .service {
    @include brick;
    @include ml(0);

    min-width: 100%;

    &--download {
      @include attribute(border-color, secondary);
    }
  }

  .info {
    @include brick-info;
  }

  .titel {
    @include brick-title;
  }

  .untertitel {
    @include brick-subtitle;
  }

  .inhalt {
    @include brick-content;
  }

  .download-link {
    @include brick-download;
  }

  a {
    &::before {
      @include mr(xs);

      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background: transparent url("/assets/img/icons/active/external-link-active.svg") no-repeat center center;
      background-size: 100%;
      float: left;
    }
  }

  .image {
    @include brick-image;
  }

  .download-zip {
    @include brick-download-all;
  }
}
